<template>
  <section
    v-if="!isPassInstall"
    :class="[$style.section, !isIOS && $style.ios, !isMobile && $style.desktop]"
  >
    <instruction v-if="isIOS"></instruction>
    <div :class="$style.content">
      <icon name="logo" :class="$style.logo" />
      <v-avatar
        :class="$style.avatar"
        :src="appAvatarSrc()"
        :srcset="appAvatarSrcset()"
        size="xl"
        progress-width="4"
        progress-color="secondary"
        inner-color="white"
        is-rect
      />
      <div :class="$style.info">
        <h3 :class="$style.name">{{ appName() }}</h3>
        <v-read-more
          v-if="appDescription()"
          :class="$style.description"
          :content="appDescription()"
          size="md"
          name="more"
        />
      </div>
      <v-grid :length="postsLength" :is-infinite="false" class-name="posts">
        <v-post-preview
          v-for="post in posts.slice(0, 3)"
          :key="post.id"
          :post="post"
          :class="$style.post"
        />
        <template #placeholder v-if="posts.length > 0">
          <p-post-preview v-for="i in 3" :key="i" />
        </template>
      </v-grid>
      <h5 v-if="isIOS" :class="$style.title">{{ $t('install.safari.title') }}:</h5>
    </div>
    <div :class="[$style.footer, isIOS && $style.ios]">
      <template v-if="!isIOS">
        <p :class="$style.text">{{ loading ? $t('install.wait') : $t('install.text') }}</p>
        <v-button size="xxl" :class="$style.button" @click="onInstall">
          <svg
            v-if="loading"
            fill="var(--supster-primary)"
            viewBox="0 0 12 12"
            width="12px"
            height="12px"
          >
            <!-- eslint-disable -->
            <path
              d="M12 6C12 7.26708 11.5989 8.50163 10.8541 9.52671C10.1093 10.5518 9.05916 11.3148 7.8541 11.7063C6.64904 12.0979 5.35096 12.0979 4.1459 11.7063C2.94084 11.3148 1.89067 10.5518 1.1459 9.52671C0.40113 8.50163 -1.10771e-07 7.26708 0 6C1.10771e-07 4.73292 0.40113 3.49837 1.1459 2.47329C1.89067 1.4482 2.94084 0.685208 4.1459 0.293661C5.35096 -0.0978871 6.64904 -0.0978869 7.8541 0.293661L7.59453 1.09255C6.55818 0.755817 5.44182 0.755817 4.40547 1.09255C3.36912 1.42928 2.46597 2.08545 1.82547 2.96703C1.18497 3.8486 0.84 4.91031 0.84 6C0.84 7.08968 1.18497 8.1514 1.82547 9.03297C2.46597 9.91455 3.36912 10.5707 4.40547 10.9075C5.44182 11.2442 6.55818 11.2442 7.59453 10.9075C8.63088 10.5707 9.53403 9.91455 10.1745 9.03297C10.815 8.1514 11.16 7.08968 11.16 6H12Z"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 6 6"
                to="360 6 6"
                repeatCount="indefinite"
              />
            </path>
            <!-- eslint-enable -->
          </svg>
          {{ loading ? $t('install.pleaseWait') : $t('install.title') }}
        </v-button>
      </template>
      <div v-else :class="$style.message">
        <icon :class="$style.add" name="add" />
        <i18n :class="$style.text" path="install.safari.text" tag="p">
          <template #icon>
            <icon :class="$style.upload" name="upload" />
          </template>
        </i18n>
        <div :class="$style.pointer"></div>
      </div>
      <v-button
        v-if="isSkipInstall"
        :class="$style.skip"
        color="transparent"
        :is-disabled-padding="true"
        @click="onInstallApp"
      >
        <icon name="browser-link" width="14" height="12" />
        {{ $t('install.skip') }}
      </v-button>
    </div>
  </section>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VButton from '@elements/VButton.vue'
import VAvatar from '@layouts/avatar/VAvatar.vue'
import VReadMore from '@layouts/VReadMore.vue'
import VGrid from '@layouts/VGrid.vue'
import PPostPreview from '@placeholders/PPostPreview.vue'
import VPostPreview from '@layouts/postPreview/VPostPreview.vue'
import Instruction from '@/components/install/Instruction.vue'

import { createChat } from '@modules/chat/helpers/chat'

export default Vue.extend({
  name: 'Install',
  components: {
    VPostPreview,
    VAvatar,
    VGrid,
    VReadMore,
    VButton,
    PPostPreview,
    Instruction,
  },
  data() {
    return {
      deferredPrompt: undefined,
      isClientCreated: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters('App', [
      'app',
      'appId',
      'appAvatarSrc',
      'appAvatarSrcset',
      'appName',
      'appDescription',
      'isMyAppGPT',
    ]),
    ...mapGetters('Client', ['client', 'clientBot']),
    ...mapGetters('Me', ['accessToken', 'isLoggedIn', 'stream', 'isSkipInstall', 'isPassInstall']),
    ...mapGetters('Install', ['posts', 'postsLength']),
    ...mapGetters('Page', ['pageLoading', 'isIOS', 'isIOSInstalled', 'isMobile']),
    myAppId() {
      return this.app?.id
    },
  },
  methods: {
    ...mapActions('Client', ['postClient', 'getClientBot']),
    ...mapActions('Install', ['getInstall']),
    ...mapMutations('Me', ['setAccessToken']),
    ...mapMutations('Page', ['setPageIsHeader', 'setPageIsFooter']),
    ...mapMutations('Chat', ['setChat']),
    onInstall() {
      this.loading = true
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt()
      }
    },
    async onInstallApp() {
      if (!this.isLoggedIn && !this.isClientCreated) {
        this.isClientCreated = true
        await this.postClient({
          app_id: this.appId(),
          name: '',
          stream: this.stream,
        })
        if (this.isMyAppGPT) {
          await this.getClientBot()
          const { id, name, avatar } = this.clientBot
          const chat = createChat({
            type: 3,
            client_id: id,
            name,
            image: avatar,
            client: this.client,
            recipient: this.clientBot,
          })
          this.setChat(chat)
        }
      }
      await this.$router.push({ name: 'Profile' })
    },
    isBeforeInstallPromptEvent(event) {
      return Boolean(event) && 'prompt'
    },
    onBeforeInstallPrompt(event) {
      if (this.isBeforeInstallPromptEvent(event)) {
        this.deferredPrompt = event
      }
    },
  },
  watch: {
    myAppId: {
      handler(myAppId) {
        if (myAppId && this.isIOSInstalled && this.isIOS && !this.isLoggedIn) {
          this.onInstallApp()
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    this.setPageIsHeader(false)
    this.setPageIsFooter(false)
    await this.getInstall()
    await this.onInstallApp()
  },
  created() {
    window.addEventListener('beforeinstallprompt', this.onBeforeInstallPrompt)
    window.addEventListener('appinstalled', this.onInstallApp)
    // if (this.isPassInstall) {
    //   this.onInstallApp()
    // }
  },
  beforeDestroy() {
    this.setPageIsHeader(true)
    this.setPageIsFooter(true)
    window.removeEventListener('beforeinstallprompt', this.onBeforeInstallPrompt)
    window.removeEventListener('appinstalled', this.onInstallApp)
  },
})
</script>
<style lang="scss" module>
.section {
  display: grid;
  grid-template-rows: 1fr min-content;
  width: 100%;
  max-width: $width;
  height: 100vh;
  min-height: 500px;
  margin: 0 auto 0;
  padding: 21px 0 0;
  overflow: hidden;
  background-color: $primary;
  row-gap: 21px;

  &.ios {
    padding: 21px 0;
  }

  &.desktop {
    max-width: $widthDesktop;
  }
}

.post {
  pointer-events: none;
}

.content {
  display: grid;
  align-self: center;
  width: 100%;
  row-gap: 21px;
}

.logo {
  justify-self: center;
  width: 92px;
  height: 28px;
  color: $secondary;
}

.avatar {
  justify-self: center;
}

.info {
  display: grid;
  grid-auto-flow: row;
  justify-self: center;
  row-gap: 10px;

  & .name {
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
  }

  & .description {
    align-self: center;
    justify-self: center;
    max-width: 207px;
    text-align: center;

    button {
      justify-content: center;
    }
  }
}

.title {
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
}

.footer {
  display: grid;
  grid-template-rows: repeat(2, min-content);
  align-content: flex-end;
  padding: 0 21px;
  row-gap: 15px;

  &.ios {
    grid-template-rows: 1fr min-content;
    align-content: flex-start;
    align-self: stretch;
    padding: 0;
    row-gap: 0;
  }

  & .text {
    align-self: center;
    justify-self: center;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }

  & .button {
    gap: 12px;
    align-self: flex-end;
    padding: 17px !important;
    color: $primary !important;
  }

  & .skip {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
}

.message {
  position: relative;
  display: grid;
  grid-template-columns: 24px 1fr;
  align-self: flex-end;
  margin-bottom: 24px;
  padding: 5px 9px;
  column-gap: 13px;
  background: white;
  border-radius: 6px;

  & .add {
    z-index: 2;
    align-self: center;
    width: 24px;
    height: 24px;
    color: #7b8b8a;
  }

  & .text {
    z-index: 2;
    align-self: center;
    font-size: 12px;
  }

  & .upload {
    z-index: 2;
    align-self: center;
    width: 20px;
    height: 20px;
    margin-bottom: 5px;
    color: #6999cf;
  }

  & .pointer {
    position: absolute;
    bottom: -24px;
    left: 50%;
    z-index: 1;
    width: 32px;
    height: 32px;
    background: white;
    transform: rotate(45deg) translateX(-50%);
  }
}
</style>
